<template>
  <ion-page>
    <ion-content class="login">
<!--      <ion-card class="toto">
        <ion-item>
          <ion-icon :icon="pin" slot="start"></ion-icon>
          <ion-label>ion-item in a card, icon left, button right</ion-label>
          <ion-button fill="outline" slot="end">View</ion-button>
        </ion-item>

        <ion-card-content>
          This is content, without any paragraph or header tags,
          within an ion-card-content element.
        </ion-card-content>
      </ion-card> -->
<ion-grid fixed class="titi">
  <ion-row color="primary" justify-content-center v-if="action=='register'">
    <ion-col></ion-col>
    <ion-col  size="6">
      <div text-center>
        <h3>Create your account!</h3>
      </div>
      <div padding>
        <ion-item>
          <ion-input  name="name" type="text" placeholder="Name" ngModel required></ion-input>
        </ion-item>
        <ion-item>
          <ion-input name="email" type="email" placeholder="your@email.com" ngModel required></ion-input>
        </ion-item>
        <ion-item>
          <ion-input name="password" type="password" placeholder="Password" ngModel required></ion-input>
        </ion-item>
        <ion-item>
          <ion-input name="confirm" type="password" placeholder="Password again" ngModel required></ion-input>
        </ion-item>
      </div>
      <div padding>
        <ion-button  size="large" type="submit" [disabled]="form.invalid" expand="block">Register</ion-button>
      </div>
    </ion-col>
    <ion-col></ion-col>
  </ion-row>
</ion-grid>
  <ion-grid v-if="action == 'login'" fixed class="titi" >
    <ion-row  justify-content-center class="ion-align-items-center" >
      <ion-col></ion-col>
      <ion-col align-self-center size="12" >

        <ion-card style="background: none">
          <ion-item>
            <ion-label><ion-text> Authentification </ion-text></ion-label>
          </ion-item>

          <ion-card-content>
            <ion-item>
              <ion-input name="email" type="email" placeholder="votreNom@email.com" v-model="user.login"  required></ion-input>
            </ion-item>
            <ion-item>
              <ion-input name="password" type="password" placeholder="Mot de passe"  ></ion-input>
            </ion-item>
            <ion-button size="large" type="submit" @click="entrer()" expand="block">Login</ion-button>
          </ion-card-content>
        </ion-card>

      </ion-col>
      <ion-col></ion-col>
    </ion-row>
  </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import shared from "../shared/shared";
import { ref } from 'vue'


export default {
  name: "Signin",
  inject: {shared: "shared"},
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const  action = ref(route.params.action);
    const user = ref({login:null, pwd:"a", nom:"Je suis A"})
    const entrer = () => {
      if (user.value.login == "a")
      {
        localStorage.monUser = user.value.nom;
        //alert('je peux entrer')
        console.log(context)
        shared.user.value = user.value.nom;
        //context.attrs.parent.jscReload();
        //context.emit('loggedin')
        router.push('/');
      }
    }

    return {
      action,
      user,
      entrer
    }
  }
}
</script>

<style scoped>
.titi {
  --ion-grid-width-sm:              400px;
  --ion-grid-width-md:              400px;
  --ion-grid-width-lg:              400px;
  --ion-grid-width-xl:              400px;
}
.toto {
  --background: rgba(49,70,213,0.1);
}
.login {
  --background: #fff url("/img/pexels-anand-dandekar-1532771.jpg") no-repeat center center / cover;
}
ion-item{
  --background: none;
  //--color: #fff;
}

ion-button{
  --background: #062f77;
}
</style>
